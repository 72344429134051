// Event snippet for Web store button conversion page. In your html page, add the snippet and call gtag_report_conversion when someone clicks on the chosen link or button. -->

function gtag_report_conversion(url) {
    var callback = function () {
        if (typeof (url) != 'undefined') {
            window.location = url;
        }
    };
    gtag('event', 'conversion', {
        'send_to': 'AW-16557136695/2e2oCPCk0a0ZELe2h9c9',
        'event_callback': callback
    });
    return false;
}

// toggle burger menu
function toggleMenu() {
    const burger = document.querySelector('.burger-menu');
    const mobileNav = document.querySelector('.mobile-navigation');
    // burger.classList.toggle('active');
    if (mobileNav.style.display === "flex") {
        mobileNav.style.display = "none";
    } else {
        mobileNav.style.display = "flex";
    }
}

// handle download extensions button click
function handleDownloadButton() {
    const userAgent = navigator.userAgent;
    // console.log(userAgent);
    if (isMobileDevice()) displayMobileMessage(true)
    else if (userAgent.includes('Chrome')) {
        // Logic for Chrome
        
        // console.log("Chrome browser detected.");
        window.open('https://chrome.google.com/webstore/detail/assistax2s/pkedmmenikcnokglcndeeodoaagpgago', '_blank');

        // Call the Google Ads conversion tracking
        gtag_report_conversion();
    } else if (userAgent.includes('Firefox')) {
        // Logic for Firefox
        console.log("Firefox browser detected.");
        // console.log("Chrome browser detected.");
        window.open('https://chrome.google.com/webstore/detail/assistax2s/pkedmmenikcnokglcndeeodoaagpgago', '_blank');
    } else {
        // Default behavior or suggestion
        console.log("Other browser detected.");
        // console.log("Chrome browser detected.");
        window.open('https://chrome.google.com/webstore/detail/assistax2s/pkedmmenikcnokglcndeeodoaagpgago', '_blank');
        
    }
}

// display modal message on mobile devices
function displayMobileMessage(show) {
    const messageDiv = document.querySelector('.mobile-message');
    if (show)messageDiv.classList.add('visible');
    else messageDiv.classList.remove('visible');
    
    

    // Hide the message after a delay
    // setTimeout(() => {
    //     messageDiv.classList.remove('visible');
    // }, 7000); // 7 seconds until the message hides
}

// check if user's device is a mobile device
function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

document.addEventListener('DOMContentLoaded', function () {

    // animate elements when content loads
    
    setTimeout(() => {
        document.getElementById('atx2s-header').classList.add('slide-in-top-view');
    }, 200);
    setTimeout(() => {
        document.getElementById('hero-text-title').classList.add('slide-in-top-view');
    }, 400);
    setTimeout(() => {
        document.getElementById('hero-text-subtitle').classList.add('slide-in-top-view');
    }, 600);
    setTimeout(() => {
        document.querySelector('.hero-image-icon').classList.add('scale-in-view');
    }, 800);
    setTimeout(() => {
        document.querySelector('.hero-cta').classList.add('slide-in-bottom-view');
    }, 1000);
    setTimeout(() => {
        document.querySelector('.cta-button1').classList.add('tada-view');
    }, 2000);
    

    // dynamically set copyright date
    // document.getElementById('current-year').textContent = new Date().getFullYear();

    // smooth scrolling
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
            e.preventDefault();
            const target = document.querySelector(this.getAttribute('href'));
            const headerOffset = document.querySelector('#atx2s-header').clientHeight;
            const elementPosition = target.getBoundingClientRect().top + document.documentElement.scrollTop;
            const offsetPosition = elementPosition - headerOffset;
    
            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        });
    });

    // Animate page on user scroll
    const scrollAnimation = () => {
        const rows = new Map(); // To store the top position and count of elements in each row

        document.querySelectorAll('.scale-in, .slide-in-bottom, .slide-in-left, .slide-in-right').forEach((elem, index) => {
            let elemTop = elem.offsetTop;
            let delay = 0;

            if (rows.has(elemTop)) {
                // Increment delay based on the number of elements in the same row
                delay = 200 * rows.get(elemTop);
                rows.set(elemTop, rows.get(elemTop) + 1);
            } else {
                rows.set(elemTop, 1);
            }

            // Determine triggerPoint based on parent section ID
            let triggerPoint = window.innerHeight - 100; // Default trigger point offset
            const sectionId = elem.closest('section').id; // Get the closest section's ID

            if (sectionId === 'avantages') {
                triggerPoint = window.innerHeight - 50;
            } else if (['how-it-works', 'abo'].includes(sectionId)) {
                triggerPoint = window.innerHeight - 100;
            } else if (['faq', 'tutos'].includes(sectionId)) {
                triggerPoint = window.innerHeight;
            }

            if (elem.getBoundingClientRect().top < triggerPoint) {
                setTimeout(() => {
                    elem.classList.add('slide-in-view');
                }, delay);
            }
        });

        document.querySelectorAll('.sm-plan, .pro-plan').forEach((elem) => {
            let triggerPoint = window.innerHeight - 150; // Adjust trigger point as needed
            if (elem.getBoundingClientRect().top < triggerPoint) {
                if (elem.classList.contains('sm-plan')) {
                    elem.classList.add('sm-plan-animate');
                } else if (elem.classList.contains('pro-plan')) {
                    elem.classList.add('pro-plan-animate');
                }
            }
        });

        document.querySelectorAll('.section-title').forEach((title) => {
            let triggerPoint = window.innerHeight - 70
            if (title.getBoundingClientRect().top < triggerPoint) {
                title.classList.add('slide-in-view');
            }
        });
    };

    window.addEventListener('scroll', scrollAnimation, { passive: true });

    document.querySelectorAll('.nav-link').forEach(link => {
        link.addEventListener('click', () => {
            document.querySelector('.mobile-navigation').style.display = 'none'
        });
    });

    // Get all download buttons and add event listeners
    const buttons1 = document.querySelectorAll('.cta-button1');
    const buttons2 = document.querySelectorAll('.cta-button2');

    buttons1.forEach(function (button) {
        button.addEventListener('click', handleDownloadButton);
    });

    buttons2.forEach(function (button) {
        button.addEventListener('click', handleDownloadButton);
    });

    const burger_menu = document.querySelector('.burger-menu');
    burger_menu.addEventListener('click', toggleMenu)

    // Select all the FAQ questions
    const faqItems = document.querySelectorAll('#faq .faq-item');
    // toggle expand/collapse for FAQ questions
    faqItems.forEach(item => {
        const arrow = item.querySelector('.faq-heading img');
        const answer = item.querySelector('.faq-answer');
    
        item.addEventListener('click', function () {
            const isExpanded = answer.style.display !== 'none';
    
            if (isExpanded) {
                answer.classList.remove('open');
                setTimeout(() => {
                    answer.style.display = 'none';
                    arrow.classList.toggle('flipped');
                }, 300); // Transition duration
            } else {
                answer.style.display = 'block';
                setTimeout(() => {
                    answer.classList.add('open');
                    arrow.classList.toggle('flipped');
                }, 15);
            }
        });
    });  
    
    document.getElementById('emailForm').addEventListener('submit', function(event) {
        event.preventDefault(); // Prevent the default form submission
        const email = document.forms['emailForm']['email'].value;
        const statusMessage = document.getElementById("emailStatusMessage")
        fetch('/email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email: email })
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                statusMessage.style.color = '#808080'
                statusMessage.textContent = `Email envoyé à ${data.success}`
                setTimeout(() => {
                    statusMessage.textContent = ''
                    displayMobileMessage(false)
                }, 5000);
            }
            else if (data.error) {
                statusMessage.style.color = 'red'   
            statusMessage.textContent = data.error
            setTimeout(() => {
                statusMessage.textContent = ''
            }, 5000);
            }
        })
        .catch((error) => {
            statusMessage.style.color = 'red'   
            statusMessage.textContent = error.message
            setTimeout(() => {
                statusMessage.textContent = ''
            }, 5000);
        });
    });

    document.getElementById('closeMobilePopup').addEventListener('click', () => displayMobileMessage(false));
});